<app-modal-layout (clickedOutside)="closed.emit()" *ngIf="show">
  <div class="rounded-2xl bg-white p-14">
    <div
      class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
    >
      <div class="h-16">
        <app-icon icon="locked-user" />
      </div>
    </div>

    <ng-container *ngIf="view === 'initial'; else responseView">
      <ng-container *ngIf="userRole === 'ADMIN'">
        <div class="mt-6 text-center">
          <h2 i18n class="mb-2 text-2xl font-medium">Grant admin access?</h2>
          <p i18n class="font-light">
            Are you sure you would like to grant admin <br />
            access to {{ userName }}?
          </p>
        </div>

        <div class="mt-8 flex flex-col">
          <app-button
            type="submit"
            i18n-text
            (click)="updateUserRole()"
            text="Yes, grant admin access"
            classes="w-full"
          />
          <app-button
            i18n-text
            text="Cancel"
            (click)="closed.emit()"
            classes="w-full mt-4"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="userRole === 'USER'">
        <div class="mt-6 text-center">
          <h2 i18n class="mb-2 text-2xl font-medium">Remove admin access?</h2>
          <p i18n class="font-light">
            Are you sure you would like to remove admin <br />
            access from {{ userName }}?
          </p>
        </div>

        <div class="mt-8 flex flex-col">
          <app-button
            type="submit"
            i18n-text
            (click)="updateUserRole()"
            text="Yes, remove admin access"
            classes="w-full"
          />
          <app-button
            i18n-text
            text="Cancel"
            (click)="closed.emit()"
            classes="w-full mt-4"
          />
        </div>
      </ng-container>
    </ng-container>

    <ng-template #responseView>
      <ng-container *ngIf="pending">
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Updating user role</h2>
          <p i18n class="mb-8">Please wait.</p>
        </div>
      </ng-container>
      <ng-container *ngIf="!pending">
        <ng-container *ngIf="error; else responseSuccess">
          <div class="mt-6 text-center">
            <h2 i18n class="mb-2 text-2xl font-medium">An error occurred</h2>
            <p i18n class="font-light">
              Unable to update access for '{{ userName }}', please try again.
            </p>
          </div>

          <div class="mt-8 flex flex-col">
            <app-button
              type="submit"
              i18n-text
              (click)="updateUserRole()"
              text="Try again"
              classes="w-full"
            />
            <app-button
              i18n-text
              text="Cancel"
              (click)="close()"
              classes="w-full mt-4"
            />
          </div>
        </ng-container>

        <ng-template #responseSuccess>
          <div class="mt-6 text-center">
            <h2 i18n class="mb-2 text-2xl font-medium">Access updated!</h2>
            <p i18n class="font-light">
              Access has been successfully updated for <br />
              '{{ userName }}'.
            </p>
          </div>

          <div class="mt-8 flex flex-col">
            <button (click)="close()" i18n class="text-alpha-blue">
              Back to dashboard
            </button>
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </div>
</app-modal-layout>
